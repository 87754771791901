<template>
  <div class="font-source">
    <section class="flex justify-between items-center">
      <h4 class="font-semibold text-ansBlack text-lg lg:text-xl">
        Batch Invoices
      </h4>

      <button
        @click="showCreate = true"
        class="bg-ansLemon text-ansGreen border border-ansGreen px-4 lg:px-4 py-3 rounded flex gap-3 items-center"
      >
        <img src="@/assets/images/icons/plus.svg" alt="icons" />
        <span>New Invoice</span>
      </button>
    </section>

    <section class="my-11">
      <div class="table-box">
        <h4 class="px-2 mb-5">List of generated invoices by batch</h4>

        <api-table
          apiUrl="/invoice/batch"
          :fields="fields"
          :show-page="true"
          :show-search="true"
          detail-row-component="batch-invoices"
          :bus="bus"
          track-by="batchId"
        >
          <template slot="issuer" slot-scope="props">
            <span
              >{{ props.rowData.issuer }} ({{ props.rowData.issuerPhone }})
            </span>
          </template>

          <template slot="actions" slot-scope="props">
            <div class="flex gap-3">
              <button
                class="px-2.5 py-1.5 text-white bg-blue-500 rounded"
                @click="onCellClicked(props.rowData)"
              >
                View
              </button>

              <button
                class="px-2.5 py-1.5 text-white bg-green-500 rounded"
                @click="printBatchInvoice(props.rowData)"
              >
                Print
              </button>
            </div>
          </template>
        </api-table>
      </div>
    </section>

    <Modal v-if="showView" v-model="showView" size="lg">
      <InvoiceBatch :batchId="batchId" />
    </Modal>

    <Modal v-if="showCreate" v-model="showCreate" size="lg">
      <CreateInvoice @done="onAddItem" />
    </Modal>
  </div>
</template>

<script>
import Vue from "vue";
import Modal from "@/components/app/Modal";
import CreateInvoice from "@/components/agent/CreateInvoice";
import InvoiceBatch from "@/components/home/InvoiceBatch";
import BatchInvoices from "@/components/agent/BatchInvoices";
Vue.component("batch-invoices", BatchInvoices);
export default {
  name: "AgentInvoicesBatch",

  components: {
    Modal,
    CreateInvoice,
    InvoiceBatch,
  },

  data() {
    let sv = "hidden lg:table-cell";
    let tc = "bg-gray-100 py-3.5 text-cadet font-semibold text-sm";
    let dc = "py-2 text-xs text-romanSilver";
    return {
      bus: new Vue(),
      showView: false,
      showCreate: false,
      batchId: null,
      fields: [
        {
          name: "id",
          title: "S/N",
          titleClass: `${tc} text-left pl-4 `,
          dataClass: `${dc} text-left pl-4`,
        },
        {
          name: "batchId",
          title: "Batch ID",
          titleClass: `${tc} text-left`,
          dataClass: `${dc} uppercase`,
        },
        {
          name: "issuer",
          title: "Created by",
          titleClass: `${tc} text-left ${sv}`,
          dataClass: `${dc} ${sv}`,
          formatter: (val) => {
            return val ? val : "<i class='text-red-300'>No Issuer<i/>";
          },
        },
        {
          name: "totalAmount",
          title: "Amount(₦)",
          titleClass: `${tc} text-left ${sv}`,
          dataClass: `${dc} ${sv} `,
          formatter: (value) => this.moneyFormatter(value),
        },
        {
          name: "totalInvoice",
          title: "Number of invoice",
          titleClass: `${tc} text-left ${sv}`,
          dataClass: `${dc} uppercase ${sv} `,
        },
        {
          name: "createdAt",
          title: "Date Created",
          titleClass: `${tc} text-left ${sv}`,
          dataClass: `${dc} ${sv}`,
          formatter: (val) => this.moment(val).format("DD-MM-YYYY"),
        },
        {
          name: "actions",
          title: "Actions",
          titleClass: `${tc} text-right lg:text-left pr-4`,
          dataClass: `${dc} flex justify-end lg:justify-start`,
        },
      ],
    };
  },

  methods: {
    onAddItem() {
      this.$events.fire("table-refresh");
      this.showCreate = false;
    },
    onCellClicked(data) {
      data._id = data.batchId;
      this.bus.$emit("cellClicked", data);
    },
    printBatchInvoice(data) {
      console.log(data);
      this.batchId = data.batchId;
      this.showView = true;
    },
  },
};
</script>

<style scoped>
.table-box {
  @apply rounded-md py-4 px-2 w-full mt-5 lg:mt-0;
  border: 0.5px solid #d9d9d9;
  box-shadow: 0px 4px 11px rgba(200, 212, 219, 0.2);
}
.table-box h4 {
  @apply text-romanSilver font-bold;
}
</style>
