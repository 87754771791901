<template>
  <div class="font-source">
    <div v-if="getting" class="flex justify-center items-center h-[50vh]">
      <spinner class="w-20 h-20" color="ansGreen" />
    </div>

    <div
      v-else-if="!getting && !subagent"
      class="flex flex-col gap-4 justify-center items-center h-[50vh]"
    >
      <img
        class="mx-auto"
        src="@/assets/images/notfound.svg"
        alt="loading icon"
        width="168"
        height="170"
      />
      <p class="text-center fw-bold">Subagent Not Found!</p>
    </div>

    <div v-else>
      <section class="flex flex-col lg:flex-row justify-between gap-5">
        <div class="w-full lg:w-2/3">
          <div
            class="flex gap-4 items-center text-romanSilver font-bold bg-britGray"
          >
            <button @click="$router.go(-1)" class="p-4">
              <img src="@/assets/images/icons/back-arrow.svg" alt="icons" />
            </button>
            <h4 class="font-bold text-lg lg:text-xl">Basic Information</h4>
          </div>

          <div class="text-cadet w-full">
            <div class="info">
              <h5>Agent Name:</h5>
              <h6>{{ subagent.name }}</h6>
            </div>

            <div class="info">
              <h5>Email:</h5>
              <h6>{{ subagent.email }}</h6>
            </div>

            <div class="info">
              <h5>Phone number:</h5>
              <h6>{{ subagent.phone }}</h6>
            </div>

            <div class="info">
              <h5>Date Created:</h5>
              <h6>{{ subagent.createdAt | moment("DD/MM/YYYY") }}</h6>
            </div>
          </div>
        </div>

        <div
          class="bg-britGray text-white w-full lg:w-1/3 rounded flex flex-col gap-4 p-4"
        >
          <div
            class="flex flex-col justify-center items-center h-30 text-darkGreen shadow"
          >
            <p>Last Login</p>
            <p v-if="subagent.lastLogin" class="font-bold">
              {{ subagent.lastLogin | moment("Do MMM YYYY ") }}
            </p>
            <p v-else class="font-bold">N/A</p>
          </div>

          <div v-if="subagent.lastLogin">
            <button
              v-if="subagent.isActive"
              :disabled="processing"
              class="bg-red-500 py-4 rounded"
              @click="deactivateAgent('deactivate')"
            >
              Disable Sub-Agent
            </button>

            <button
              v-else
              :disabled="processing"
              class="bg-green-500 py-4 rounded"
              @click="deactivateAgent('activate')"
            >
              Enable Sub-Agent
            </button>
          </div>

          <div
            v-else
            class="text-black py-4 rounded border border-cadet text-center"
          >
            Not activated yet
          </div>
        </div>
      </section>

      <section class="my-11 grid grid-cols-1 lg:grid-cols-4 gap-5">
        <div class="small-boxes">
          <h6 class="text-cadet">
            Total Value of<br />
            <span class="ml-1 text-xs font-bold">TRANSACTIONS</span>
          </h6>

          <h3 class="mt-4 text-ansBlack font-black">
            {{ totalTransactionAmount | toCurrency }}
          </h3>
        </div>
        <div class="small-boxes">
          <h6 class="text-cadet">
            Total Value of Generated<br />
            <span class="ml-1 text-xs font-bold">INVOICES</span>
          </h6>

          <h3 class="mt-4 text-ansBlack">
            {{ totalGeneratedTaxIncome | toCurrency }}
          </h3>
        </div>
        <div class="small-boxes">
          <h6 class="text-cadet">
            Total Number of <br />
            <span class="ml-1 text-xs font-bold">TRANSACTIONS</span>
          </h6>

          <h3 class="mt-4 text-ansBlack">
            <span class="font-black">{{ totalNumberTransaction }}</span>
          </h3>
        </div>
        <div class="small-boxes">
          <h6 class="text-cadet">
            Total Number of Generated<br />
            <span class="ml-1 text-xs font-bold">INVOICES</span>
          </h6>

          <h3 class="mt-4 text-ansBlack">
            <span class="font-black">{{ totalGeneratedInvoice }}</span>
          </h3>
        </div>
      </section>

      <section class="mt-6">
        <h3 class="text-cadet font-bold text-base lg:text-2xl">
          Recent Activity
        </h3>

        <div
          class="block lg:flex justify-between font-source mt-3 pb-10 gap-10"
        >
          <div class="table-box">
            <div class="flex justify-between px-2 mb-4">
              <h4>Invoices</h4>

              <!-- <router-link :to="{ name: 'Agent Invoices' }">
              View all
            </router-link> -->
            </div>

            <my-table
              :table-data="recentInvoices"
              :fields="fidInv"
              :show-page="false"
              :show-search="false"
            >
            </my-table>
          </div>

          <div class="table-box">
            <div class="flex justify-between px-2 mb-4">
              <h4>Transactions</h4>

              <!-- <router-link :to="{ name: 'Agent Transactions' }">
              View all
            </router-link> -->
            </div>

            <my-table
              :table-data="recentTransactions"
              :fields="fidTran"
              :show-page="false"
              :show-search="false"
            >
            </my-table>
          </div>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
export default {
  name: "SubAgent",

  data() {
    // let sv = "hidden lg:table-cell";
    let tc = "w-1/3 bg-gray-100 py-2.5 text-cadet font-semibold text-sm";
    let dc = "w-1/3 py-2 text-xs text-romanSilver";
    return {
      getting: true,
      processing: false,
      agentId: null,
      subagent: null,
      recentInvoices: [],
      recentTransactions: [],
      totalNumberTransaction: 0,
      totalGeneratedTaxIncome: 1,
      totalGeneratedInvoice: 1,
      totalTransactionAmount: 0,
      fidInv: [
        {
          name: "invoiceNumber",
          title: "Invoice number",
          titleClass: `${tc} text-left pl-4 `,
          dataClass: `${dc} text-left pl-4`,
        },
        {
          name: "invoiceStatus",
          title: "Invoice status",
          titleClass: `${tc}`,
          dataClass: `${dc} uppercase text-xs text-center`,
        },
        {
          name: "dateOfIssuance",
          title: "Created",
          titleClass: `${tc}`,
          dataClass: `${dc} text-center`,
          formatter: (val) => this.moment(val).format("DD-MM-YYYY"),
        },
      ],
      fidTran: [
        {
          name: "referenceCode",
          title: "Transaction code",
          titleClass: `${tc} text-left pl-4 `,
          dataClass: `${dc} text-left pl-4`,
        },
        {
          name: "amount",
          title: "Amount (₦)",
          titleClass: `${tc}`,
          dataClass: `${dc} text-center`,
        },
        {
          name: "createdAt",
          title: "Created",
          titleClass: `${tc}`,
          dataClass: `${dc} text-center`,
        },
      ],
    };
  },

  async created() {
    this.agentId = this.$route.params.id;

    await this.getData();
  },

  methods: {
    async getData() {
      try {
        this.getting = true;

        const res = await this.$http.get("/agent/sub-agent/" + this.agentId);
        this.getting = false;

        if (!res) {
          this.subagent = null;
          return;
        }

        const { data } = res;

        this.subagent = data.data.subAgent;
        this.recentInvoices = data.data.recentInvoices;
        this.recentTransactions = data.data.recentTransactions;
        this.totalGeneratedTaxIncome = data.data.totalGeneratedTaxIncome;
        this.totalGeneratedInvoice = data.data.totalGeneratedInvoice;
        this.totalNumberTransaction = data.data.totalNumberTransaction;
        this.totalTransactionAmount = data.data.totalTransactionAmount;
      } catch (err) {
        console.log(err);
        this.getting = false;
      }
    },

    async deactivateAgent(action) {
      try {
        this.processing = true;
        let durl = "agent/deactivate-sub-agent/" + this.agentId;

        if (action == "activate") {
          durl = "agent/activate-sub-agent/" + this.agentId;
        }

        const res = await this.$http.post(durl);
        this.processing = false;

        if (!res) {
          return;
        }

        const { data } = res;

        this.$swal({
          icon: "success",
          text: data.message,
        });
      } catch (error) {
        this.processing = false;
        console.log(error);
      }
    },
  },
};
</script>

<style scoped>
.small-boxes {
  @apply rounded bg-[#F5F5F5] p-5;
}
.table-box {
  @apply rounded-md py-4 px-2 w-full mt-5 lg:mt-0;
  border: 0.5px solid #d9d9d9;
  box-shadow: 0px 4px 11px rgba(200, 212, 219, 0.2);
}
.table-box h4 {
  @apply text-romanSilver font-bold;
}
.table-box > div > a {
  @apply text-ansGreen font-semibold;
}
.info {
  @apply flex justify-between w-full lg:w-10/12 items-center gap-4 lg:gap-20 px-4 mt-8;
}
.info h5 {
  @apply font-semibold w-1/2 lg:w-auto;
}
.info h6 {
  @apply w-1/2 lg:w-auto text-right;
}
</style>
