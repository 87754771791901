<template>
  <div class="font-source">
    <section class="block items-center">
      <h4 class="font-semibold text-ansBlack text-lg lg:text-xl">
        Transactions
      </h4>
    </section>

    <section class="my-11">
      <div class="table-box">
        <h4 class="px-2 mb-5">List of transactions</h4>

        <api-table
          apiUrl="/transaction"
          :fields="fields"
          :show-page="true"
          :show-search="true"
          detail-row-component="agent-drop-down"
          :bus="bus"
        >
          <template slot="itemName" slot-scope="props">
            <div @click="onCellClicked(props.rowData)" class="cursor-pointer">
              <span>{{ props.rowData.firstName }} </span>
              <span>{{ props.rowData.lastName }}</span>
            </div>
          </template>

          <template slot="actions" slot-scope="props">
            <div class="flex gap-3">
              <button
                class="px-2.5 py-1.5 text-white bg-blue-500 rounded"
                @click="viewOne(props.rowData)"
              >
                View
              </button>
              <!-- <button
                class="px-3 py-1.5 text-white bg-ansGreen rounded"
                @click="onAction('edit-item', props.rowData, props.rowIndex)"
              >
                Edit
              </button>
              <button
                class="px-3 py-1.5 text-white bg-cadet rounded"
                @click="onAction('delete-item', props.rowData, props.rowIndex)"
              >
                Disable
              </button> -->
            </div>
          </template>
        </api-table>
      </div>
    </section>

    <Modal v-if="showView" v-model="showView" size="md">
      <Transaction :invoice="invoice" />
    </Modal>
  </div>
</template>

<script>
import Vue from "vue";
import Modal from "@/components/app/Modal.vue";
import Transaction from "@/components/agent/Transaction.vue";
import AgentDropDown from "@/components/admin/AgentDropDown";
Vue.component("agent-drop-down", AgentDropDown);
export default {
  name: "Transactions",

  components: {
    Modal,
    Transaction,
  },

  data() {
    let sv = "hidden lg:table-cell";
    let tc = "bg-gray-100 py-3.5 text-cadet font-semibold text-sm";
    let dc = "py-2 text-xs text-romanSilver";
    return {
      bus: new Vue(),
      showCreateSub: false,
      showView: false,
      invoice: null,
      fields: [
        {
          name: "id",
          title: "S/N",
          titleClass: `${tc} text-left pl-4 `,
          dataClass: `${dc} text-left pl-4`,
        },
        {
          name: "referenceCode",
          title: "Tracking number",
          titleClass: `${tc} text-left`,
          dataClass: `${dc} `,
        },
        {
          name: "invoiceNumber",
          title: "Invoice number",
          titleClass: `${tc} text-left ${sv}`,
          dataClass: `${dc} ${sv} uppercase`,
        },
        {
          name: "transactionReference",
          title: "Reference",
          titleClass: `${tc} text-left ${sv}`,
          dataClass: `${dc} ${sv}`,
        },
        {
          name: "amount",
          title: "Amount(₦)",
          titleClass: `${tc} text-left ${sv}`,
          dataClass: `${dc} ${sv} `,
          formatter: (value) => this.moneyFormatter(value),
        },
        {
          name: "transactionStatus",
          title: "Status",
          titleClass: `${tc} text-left ${sv}`,
          dataClass: `${dc} ${sv} uppercase`,
        },
        {
          name: "createdAt",
          title: "Date Created",
          titleClass: `${tc} text-left ${sv}`,
          dataClass: `${dc} ${sv}`,
          formatter: (val) => this.moment(val).format("DD-MM-YYYY"),
        },
        {
          name: "actions",
          title: "Actions",
          titleClass: `${tc} text-right lg:text-left pr-4`,
          dataClass: `${dc} flex justify-end lg:justify-start`,
        },
      ],
    };
  },

  methods: {
    onAction(action, data, index) {
      console.log("slot action: " + action, data, index);
    },
    onAddItem() {
      this.showCreate = false;
    },
    onAddSubItem() {
      this.showCreateSub = false;
    },
    onCellClicked(data) {
      this.bus.$emit("cellClicked", data);
    },
    viewOne(data) {
      this.invoice = data;
      this.showView = true;
    },
  },
};
</script>

<style scoped>
.table-box {
  @apply rounded-md py-4 px-2 w-full mt-5 lg:mt-0;
  border: 0.5px solid #d9d9d9;
  box-shadow: 0px 4px 11px rgba(200, 212, 219, 0.2);
}
.table-box h4 {
  @apply text-romanSilver font-bold;
}
</style>
