<template>
  <div>
    <Header />

    <section class="flex justify-between relative mt-18 lg:mt-20 z-1">
      <SideNav
        v-if="$store.state.general.sideOpen"
        class="nav-small block lg:hidden"
      />

      <SideNav class="hidden lg:block nav-big w-50" />

      <main
        class="w-full lg:w-[calc(100%-200px)] px-4 pt-5 lg:px-12 lg:pt-10 bg-white"
      >
        <router-view></router-view>
      </main>
    </section>
  </div>
</template>

<script>
import Header from "@/components/app/AppHeader.vue";
import SideNav from "@/components/agent/SideNav.vue";
export default {
  name: "AppFrame",

  components: {
    Header,
    SideNav,
  },

  data() {
    return {
      isOpen: false,
    };
  },
};
</script>

<style scoped>
.nav-small {
  height: calc(100vh - 74px);
}
.nav-big {
  height: calc(100vh - 96px);
}
main {
  height: calc(100vh - 100px);
  overflow-y: scroll;
}
</style>
