<template>
  <div id="transact" class="font-source mt-3">
    <div class="bg-ansLemon px-6 py-3 flex justify-between text-xs">
      <h4 class="text-cadet font-semibold">Transaction ID</h4>
      <h3 class="font-bold text-ansBlack">304048393403</h3>
    </div>

    <div class="bg-ansLemon px-6 py-3 flex justify-between text-xs mt-2">
      <h4 class="text-cadet font-semibold">Transaction status</h4>
      <h3 class="font-bold text-ansGreen uppercase">
        {{ invoice.transactionStatus }}
      </h3>
    </div>

    <div class="mt-6">
      <div class="mx-auto" style="max-width: 311px">
        <div class="bg-[#EEF5FF] py-6 text-center text-ansBlack rounded">
          <p class="text-xs">Amount received</p>
          <h4 class="text-xl font-black mt-3">
            {{ invoice.amount | toCurrency }}
          </h4>
        </div>

        <div v-if="paid" class="flex justify-between text-xs mt-4">
          <p>Sending platform</p>
          <h4 class="text-right font-bold">BIOPAY</h4>
        </div>

        <div v-if="paid" class="flex justify-between text-xs mt-3">
          <p>Mode</p>
          <h4 class="text-right font-bold">Verce card</h4>
        </div>

        <div v-if="paid" class="flex justify-between text-xs mt-3">
          <p>Card</p>
          <h4 class="text-right font-bold">5454 3434 .... 4343</h4>
        </div>

        <div class="flex justify-between text-xs mt-3">
          <p>Date</p>
          <h4 class="text-right font-bold">
            {{ invoice.createdAt | moment("Do MMM YYYY") }}
          </h4>
        </div>

        <div class="flex justify-between text-xs mt-3">
          <p>Time</p>
          <h4 class="text-right font-bold">
            {{ invoice.createdAt | moment("hh:mm:ssa") }}
          </h4>
        </div>
      </div>
    </div>

    <div class="flex justify-center px-2 mt-20 py-5 gap-6">
      <button class="px-4 py-2.5 flex gap-1 rounded border border-gray-200">
        <img src="@/assets/images/email/email_send.svg" alt="icons" />
        <span>Send via email</span>
      </button>

      <button
        @click="printInvoice"
        class="flex justify-center gap-5 px-3 py-2.5 rounded border border-gray-200"
      >
        <img
          src="@/assets/images/email/print.svg"
          alt="icons"
          style="display: inline"
        />
        <span>Print</span>
      </button>
    </div>
  </div>
</template>

<script>
export default {
  name: "Transaction",

  data() {
    return {
      getting: false,
      notFound: false,
      transaction: null,
    };
  },

  computed: {
    paid() {
      return this.invoice.transactionStatus != "pending";
    },
  },

  props: {
    invoice: {
      type: Object,
      default: () => ({}),
      required: true,
    },
  },

  async created() {
    // await this.getData();
  },

  methods: {
    async getData() {
      try {
        this.getting = true;

        const res = await this.$http.get(
          "/transaction/" + this.invoice.invoiceNumber
        );
        this.getting = false;

        if (!res) {
          return;
        }
        this.notFound = false;

        const { data } = res;
        this.transaction = data;
      } catch (err) {
        console.log(err);
        this.getting = false;
      }
    },

    printInvoice() {
      const divContents = document.getElementById("transact").innerHTML;
      const a = window.open("", "", "");
      a.document.write(
        "<html><head><title>" + document.title + "</title></head>"
      );
      a.document.write("<body>");
      a.document.write(divContents);
      a.document.write("</body></html>");
      a.document.close();
      a.focus();
      a.print();
      a.close();
      return true;
    },
  },
};
</script>

<style scoped>
@media print {
  #transact {
    max-width: 600px;
    margin: auto;
  }
}
</style>
