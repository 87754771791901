<template>
  <div class="font-source">
    <section class="flex justify-between items-center">
      <h4 class="font-semibold text-ansBlack text-lg lg:text-xl">Agents</h4>

      <button
        @click="showCreate = true"
        class="bg-ansLemon text-ansGreen border border-ansGreen px-4 py-3 rounded flex gap-3 items-center w-1/2 lg:w-auto font-semibold text-sm lg:text-base"
      >
        <img src="@/assets/images/icons/plus.svg" alt="icons" />
        <span>New Sub-Agent</span>
      </button>
    </section>

    <section class="my-11">
      <div class="table-box">
        <h4 class="px-2 mb-5">List of created agents</h4>

        <api-table
          apiUrl="/agent/sub-agents"
          :fields="fields"
          :show-page="true"
          :show-search="true"
          detail-row-component="agent-drop-down"
          :bus="bus"
        >
          <template slot="username" slot-scope="props">
            <div @click="onCellClicked(props.rowData)" class="cursor-pointer">
              <span>{{ props.rowData.name }} </span>
            </div>
          </template>

          <template slot="actions" slot-scope="props">
            <div class="flex gap-3 items-center">
              <router-link
                :to="{
                  name: 'Agent Sub Agent',
                  params: { id: props.rowData.id },
                }"
                class="px-3 py-1.5 text-white bg-blue-500 rounded"
              >
                View
              </router-link>

              <div v-if="props.rowData.lastLogin">
                <button
                  v-if="props.rowData.isActive"
                  class="px-3 py-1.5 text-white bg-cadet rounded"
                  @click="deactivateAgent('deactivate', props.rowData)"
                >
                  Disable
                </button>

                <button
                  v-else
                  class="px-3 py-1.5 text-white bg-purple-500 rounded"
                  @click="deactivateAgent('activate', props.rowData)"
                >
                  Enable
                </button>
              </div>

              <div
                v-else
                class="px-3 py-1.5 text-black border border-cadet rounded"
              >
                Not activated
              </div>
            </div>
          </template>
        </api-table>
      </div>
    </section>

    <Modal v-if="showCreate" v-model="showCreate">
      <CreateSubAgent @add-agent="onAddAgent" />
    </Modal>
  </div>
</template>

<script>
import Vue from "vue";
import Modal from "@/components/app/Modal";
import AgentDropDown from "@/components/admin/AgentDropDown";
import CreateSubAgent from "@/components/agent/CreateSubAgent";
Vue.component("agent-drop-down", AgentDropDown);
export default {
  name: "Agents",

  components: {
    Modal,
    CreateSubAgent,
  },

  data() {
    let sv = "hidden lg:table-cell";
    let tc = "bg-gray-100 py-3.5 text-cadet font-semibold text-sm";
    let dc = "py-2 text-xs text-romanSilver";
    return {
      bus: new Vue(),
      showCreate: false,
      fields: [
        {
          name: "id",
          title: "S/N",
          titleClass: `${tc} text-left pl-4 `,
          dataClass: `${dc} text-left pl-4`,
        },
        {
          name: "username",
          title: "Agent Name",
          titleClass: `${tc} text-left`,
          dataClass: `${dc} `,
        },
        {
          name: "email",
          title: "Email",
          titleClass: `${tc} text-left ${sv}`,
          dataClass: `${dc} ${sv}`,
        },
        {
          name: "phone",
          title: "Phone number",
          titleClass: `${tc} text-left ${sv}`,
          dataClass: `${dc} ${sv}`,
        },
        {
          name: "createdAt",
          title: "Date Created",
          titleClass: `${tc} text-left ${sv}`,
          dataClass: `${dc} ${sv}`,
          formatter: (val) => this.moment(val).format("DD-MM-YYYY"),
        },
        {
          name: "actions",
          title: "Actions",
          titleClass: `${tc} text-right lg:text-left pr-4`,
          dataClass: `${dc} flex justify-end lg:justify-start`,
        },
      ],
    };
  },

  methods: {
    onAddAgent() {
      this.$events.fire("table-refresh");
      this.showCreate = false;
    },
    onCellClicked(data) {
      this.bus.$emit("cellClicked", data);
    },
    async deactivateAgent(action, agent) {
      try {
        this.processing = true;
        let durl = "agent/deactivate-sub-agent/" + agent._id;

        if (action == "activate") {
          durl = "agent/activate-sub-agent/" + agent._id;
        }

        const res = await this.$http.post(durl);
        this.processing = false;

        if (!res) {
          return;
        }

        const { data } = res;

        this.$swal({
          icon: "success",
          text: data.message,
        });
        this.$events.fire("table-refresh");
      } catch (error) {
        this.processing = false;
        console.log(error);
      }
    },
  },
};
</script>

<style scoped>
.table-box {
  @apply rounded-md py-4 px-2 w-full mt-5 lg:mt-0;
  border: 0.5px solid #d9d9d9;
  box-shadow: 0px 4px 11px rgba(200, 212, 219, 0.2);
}
.table-box h4 {
  @apply text-romanSilver font-bold;
}
</style>
