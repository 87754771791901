<template>
  <div class="font-source">
    <h4 class="px-5 py-2.5 bg-ansLemon text-ansGreen text-center">
      Generate invoice
    </h4>

    <!-- Form section -->
    <section style="max-width: 600px" class="mx-auto w-full">
      <div class="flex justify-between px-4 lg:px-20 mt-2.5">
        <label for="toggler">Generate bulk invoice </label>
        <Switcher id="toggler" v-model="isBulk" />
      </div>

      <div v-if="!isBulk" class="px-4 lg:px-20 mt-2.5">
        <label for="userId">Tax payer</label>
        <v-select
          id="userId"
          label="firstName"
          :reduce="(b) => b.nin"
          :options="payers"
          v-model="userId"
          :filterable="false"
          @search="onSearchPayer"
          placeholder="-- select tax payer --"
        >
          <template v-slot:no-options="{ search, searching }">
            <template v-if="searching">
              No results found for <em>{{ search }}</em
              >.
            </template>
            <span v-else clas="text-gray-400 lowercase text-xs">
              Start typing to search for a tax payer
            </span>
          </template>

          <template slot="option" slot-scope="option">
            <span>
              {{ option.firstName }}
              {{ option.middleName }} {{ option.surname }}
            </span>
          </template>
          <template slot="selected-option" slot-scope="option">
            <span> {{ option.firstName }} {{ option.surname }} </span>
          </template>
        </v-select>
      </div>

      <div class="px-4 lg:px-20 mt-3">
        <label for="revenue">
          Revenue Item
          <span class="text-red-400">*</span>
        </label>
        <v-select
          id="revenue"
          label="revenueName"
          :reduce="(b) => b.revenueCode"
          :options="revenueItems"
          v-model="form.revenue"
          :filterable="false"
          @search="onSearch"
          placeholder="-- select revenue --"
        >
          <template v-slot:no-options="{ search, searching }">
            <template v-if="searching">
              No results found for <em>{{ search }}</em
              >.
            </template>
            <span v-else clas="text-gray-400 lowercase text-xs">
              Start typing to search for a revenue
            </span>
          </template>
        </v-select>
      </div>

      <div class="px-4 lg:px-20 mt-3">
        <label for="duration">
          Duration
          <span class="text-red-400">*</span>
        </label>
        <v-select
          id="duration"
          label="durationName"
          :reduce="(b) => b"
          :options="durations"
          v-model="form.duration"
          placeholder="-- select duration --"
        ></v-select>
      </div>

      <div class="px-4 lg:px-20 mt-4 flex justify-between gap-2">
        <div class="w-full">
          <label for="year">
            Year
            <span class="text-red-400">*</span>
          </label>
          <v-select
            id="year"
            label="label"
            :reduce="(b) => b.value"
            :options="years"
            v-model="form.year"
            placeholder="-- select a year --"
          ></v-select>
        </div>

        <div v-if="isBulk" class="w-full">
          <label for="quantity">
            Number of Invoice(s)
            <span class="text-red-400">*</span>
          </label>
          <input
            type="number"
            class="input"
            id="quantity"
            min="1"
            max="100"
            placeholder="Enter number of invoice to generate"
            v-model="form.quantity"
          />
        </div>
      </div>
    </section>

    <!-- Table section -->
    <section v-if="revenues.length" class="px-4 lg:px-20 mt-8">
      <table>
        <tr v-for="(rev, i) in revenues" :key="i" class="w-full text-sm">
          <td class="w-1/12">{{ i + 1 }}.</td>
          <td class="w-4/12 uppercase">
            {{ rev.revenueName }} ({{ rev.durationName }})
          </td>
          <td class="w-1/12 text-center">
            <p>{{ rev.year }}</p>
          </td>
          <td class="w-2/12 text-center">
            {{ rev.amount | toCurrency }}
          </td>
          <td v-if="isBulk" class="w-1/12 text-center">
            {{ rev.quantity }}
          </td>
          <td v-if="isBulk" class="w-2/12 text-center">
            {{ (rev.amount * rev.quantity) | toCurrency }}
          </td>
          <td class="w-1/12">
            <button class="text-red-400 px-2" @click="deleteRev(rev, i)">
              Remove
            </button>
          </td>
        </tr>
        <tr>
          <td colspan="5">Total:</td>
          <td colspan="4" class="pr-5">{{ reveuneTotal | toCurrency }}</td>
        </tr>
      </table>
    </section>

    <section
      style="max-width: 600px"
      class="flex flex-col gap-5 px-4 lg:px-20 mt-8 mx-auto pb-3 w-full"
    >
      <button
        :disabled="!addReady"
        @click="addRevenue"
        class="border border-ansGreen bg-ansLemon text-ansGreen w-full text-center py-3 rounded font-semibold text-xl"
      >
        Add
        <span v-if="revenues.length">more </span>
        revenue item
      </button>

      <button
        v-if="revenues.length"
        :disabled="verifying || !generateReady"
        @click="generateInvoice"
        class="bg-ansGreen flex justify-center gap-2 text-white w-full text-center py-3 rounded font-semibold text-xl"
      >
        <span>Generate Invoice</span>
        <spinner v-if="verifying" />
      </button>
    </section>
  </div>
</template>

<script>
import Switcher from "@/components/form/Switcher";
export default {
  name: "CreateInvoice",

  components: {
    Switcher,
  },

  data() {
    return {
      isBulk: false,
      verifying: false,
      userId: null,
      form: {
        revenue: null,
        duration: null,
        year: null,
        quantity: 1,
      },
      revenueItems: [],
      payers: [],
      revenues: [],
      durations: [],
      revenueCode: null,
    };
  },

  computed: {
    qtyReady() {
      return (
        !!this.form.quantity &&
        this.form.quantity > 0 &&
        this.form.quantity <= 100
      );
    },
    addReady() {
      return (
        !!this.form.duration &&
        this.form.revenue &&
        this.form.year &&
        this.qtyReady
      );
    },
    generateReady() {
      return (
        (this.isBulk && this.revenues.length) ||
        (!this.isBulk && this.userId && this.revenues.length)
      );
    },
    years() {
      const yearArr = [];
      for (let i = new Date().getFullYear(); i >= 2000; i--) {
        yearArr.push({
          label: i,
          value: i,
        });
      }
      return yearArr;
    },
    reveuneTotal() {
      return this.revenues.reduce(
        (a, b) => a + Number(b.amount * b.quantity),
        0
      );
    },
  },

  watch: {
    async "form.revenue"(nv) {
      if (!nv) {
        this.revenueCode = null;
        this.durations = [];
        return;
      }

      this.revenueCode = nv;
      await this.getSubcritptions();
    },
    isBulk() {
      this.revenues = [];
      this.userId = null;
    },
  },

  methods: {
    onSearch(search, loading) {
      if (search.length > 2) {
        this.getRevenueItems(search, loading);
      }
    },
    async getRevenueItems(search, loading) {
      try {
        loading(true);
        const res = await this.$http.get(
          "/revenue/items?limit=100&search=" + search,
          {
            headers: { noauth: true },
          }
        );

        if (!res) {
          this.revenueItems = [];
          return;
        }

        const { data } = res;
        this.revenueItems = data.data.data;
        loading(false);
      } catch (err) {
        loading(false);
        console.log(err);
      }
    },
    onSearchPayer(search, loading) {
      if (search.length > 2) {
        this.getPayers(search, loading);
      }
    },
    async getPayers(search, loading) {
      try {
        loading(true);
        const res = await this.$http.get("/payer?limit=100&search=" + search, {
          headers: { noauth: true },
        });

        if (!res) {
          this.payers = [];
          return;
        }

        const { data } = res;
        this.payers = data.data.data;
        loading(false);
      } catch (err) {
        loading(false);
        console.log(err);
      }
    },
    async getSubcritptions() {
      try {
        const res = await this.$http.get(
          "/subscription?limit=300&search=" + this.revenueCode,
          {
            headers: { noauth: true },
          }
        );

        if (!res) {
          this.durations = [];
          return;
        }

        const { data } = res;
        this.durations = data.data.data;
      } catch (err) {
        console.log(err);
      }
    },
    deleteRev(rev, id) {
      console.log(id);
      this.revenues = this.revenues.filter((rev, idx) => id != idx);
    },
    addRevenue() {
      this.revenues.push({
        ...this.form.duration,
        year: this.form.year,
        quantity: this.form.quantity,
      });
      this.form.revenue = null;
      this.form.duration = null;
      this.form.year = null;
    },
    async generateInvoice() {
      try {
        this.verifying = true;

        let purl = "invoice/a/invoice";
        const bbd = {
          subscriptions: this.revenues.map((rev) => {
            const oj = {
              id: rev._id,
              year: rev.year,
            };

            if (this.isBulk) oj.quantity = rev.quantity;
            return oj;
          }),
        };

        if (!this.isBulk) {
          purl = "invoice/u/invoice";
          bbd.userId = this.userId;
        }

        const res = await this.$http.post(purl, bbd);
        this.verifying = false;

        if (!res) {
          return;
        }

        const { data } = res;

        this.$swal({
          icon: "success",
          text: data.message,
        });

        this.$emit("done");
      } catch (err) {
        console.log(err);
        this.verifying = false;
      }
    },
  },
};
</script>

<style scoped>
table {
  @apply border-collapse bg-oldSilver w-full text-base font-semibold text-cadet;
}
td {
  @apply py-2.5 border-b-2 border-white;
}
table tr:last-of-type {
  @apply font-bold text-lg lg:text-2xl bg-ansLemon;
}
table tr:last-of-type td {
  @apply border-t-[20px];
}
table td:first-of-type {
  @apply pl-3;
}
table td:last-of-type {
  @apply pl-1 text-center pr-1;
}
</style>
