<template>
  <div class="px-4 py-3">
    <api-table
      :apiUrl="`/invoice?search=${rowData.batchId}`"
      :fields="fields"
      :show-page="true"
      :show-search="false"
      detail-row-component="agent-drop-down"
    >
      <template slot="actions" slot-scope="props">
        <div class="flex gap-3">
          <!-- <button
            class="px-2.5 py-1.5 text-white bg-blue-500 rounded"
            @click="viewInvoice(props.rowData, props.rowIndex)"
          >
            View
          </button> -->

          <button
            v-if="props.rowData.invoiceStatus == 'unpaid'"
            class="px-2.5 py-1.5 text-white bg-red-500 rounded"
            @click="deleteInvoice(props.rowData)"
          >
            Delete
          </button>
        </div>
      </template>
    </api-table>
  </div>
</template>

<script>
export default {
  name: "BatchInvoices",

  props: {
    rowData: {
      type: Object,
      required: true,
    },
    rowIndex: {
      type: Number,
    },
  },

  data() {
    let sv = "hidden lg:table-cell";
    let tc = "bg-gray-100 py-3.5 text-cadet font-semibold text-sm";
    let dc = "py-2 text-xs text-romanSilver";
    return {
      fields: [
        {
          name: "id",
          title: "S/N",
          titleClass: `${tc} text-left pl-4 `,
          dataClass: `${dc} text-left pl-4`,
        },
        {
          name: "invoiceNumber",
          title: "Invoice number",
          titleClass: `${tc} text-left`,
          dataClass: `${dc} uppercase`,
        },
        {
          name: "customerName",
          title: "Customers",
          titleClass: `${tc} text-left ${sv}`,
          dataClass: `${dc} ${sv}`,
          formatter: (val) => {
            return val ? val : "<i class='text-red-300'>No Customer<i/>";
          },
        },
        {
          name: "referenceCode",
          title: "Reference code",
          titleClass: `${tc} text-left ${sv}`,
          dataClass: `${dc} ${sv}`,
        },
        {
          name: "amount",
          title: "Amount(₦)",
          titleClass: `${tc} text-left ${sv}`,
          dataClass: `${dc} ${sv} `,
          formatter: (value) => this.moneyFormatter(value),
        },
        {
          name: "invoiceStatus",
          title: "Status",
          titleClass: `${tc} text-left ${sv}`,
          dataClass: `${dc} uppercase text-xs  ${sv} `,
        },
        {
          name: "createdAt",
          title: "Date Created",
          titleClass: `${tc} text-left ${sv}`,
          dataClass: `${dc} ${sv}`,
          formatter: (val) => this.moment(val).format("DD-MM-YYYY"),
        },
        {
          name: "actions",
          title: "Actions",
          titleClass: `${tc} text-right lg:text-left pr-4`,
          dataClass: `${dc} flex justify-end lg:justify-start`,
        },
      ],
    };
  },

  methods: {
    viewInvoice(data) {
      console.log(data);
      this.$emit("showInvoice", data.invoiceNumber);
    },
    async deleteInvoice(da) {
      try {
        const rep = await this.$swal({
          icon: "question",
          text: "Are you sure you want to delete this invoice?",
          showDenyButton: true,
          confirmButtonText: "Yes",
          denyButtonText: "No",
        });

        if (!rep.isConfirmed) {
          return;
        }

        const res = await this.$http.delete("/invoice/" + da.invoiceNumber);

        if (!res) {
          return;
        }

        this.$events.fire("table-refresh");

        this.$swal({
          icon: "success",
          text: "Deleted Successfully",
        });
      } catch (error) {
        console.log({ error });
      }
    },
  },
};
</script>

<style scoped>
h5 {
  @apply text-sm font-semibold text-cadet px-2;
}
</style>
