<template>
  <div class="">
    <div class="flex gap-10 px-4">
      <div class="flex my-1">
        <img
          :src="rowData.image"
          alt="user image"
          class="w-12 h-12 rounded-full object-contain border"
        />
      </div>

      <div>
        <div class="flex my-1">
          <h5>Name:</h5>
          <h5>{{ rowData.firstName }} {{ rowData.lastName }}</h5>
        </div>
        <div class="flex my-1">
          <h5>Email:</h5>
          <h5>{{ rowData.email }}</h5>
        </div>
        <div class="flex my-1">
          <h5>Address:</h5>
          <h5>{{ rowData.address.address }}</h5>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "AgentDropDown",

  props: {
    rowData: {
      type: Object,
      required: true,
    },
    rowIndex: {
      type: Number,
    },
  },

  // methods: {
  //   onClick(event) {
  //     console.log("agent-detail: on-click", event.target);
  //   },
  // },
};
</script>

<style scoped>
h5 {
  @apply text-sm font-semibold text-cadet px-2;
}
</style>
