<template>
  <transition name="slidex" appear="">
    <nav
      class="w-50 lg:w-60 bg-britGray absolute lg:static left-0 bottom-0 top-px font-source"
    >
      <ul class="mt-28">
        <li class="truncate pr-2 mb-2">
          <span class="text-sm font-bold pl-1">
            {{ $store.getters["general/userNames"] }}
          </span>
          <br />
          <span class="text-xs uppercase bg-ansGreen text-white px-3 py-1">
            {{ $store.getters["general/userType"] }}
          </span>
          <span
            v-if="$store.state.general.user.agentType"
            class="text-xs uppercase bg-ansGreen text-white px-2 py-1 ml-1"
          >
            {{ $store.state.general.user.agentType }}
          </span>
        </li>

        <hr />
        <li>
          <router-link
            :to="{ name: 'Agent Dashboard' }"
            active-class="nav-active"
            @click.native="closeNav"
          >
            <img
              src="@/assets/images/icons/dashboard.svg"
              alt="icons"
              class="h-6 w-6"
            />
            <span>Overview</span>
          </router-link>
        </li>

        <li>
          <router-link
            :to="{ name: 'Agent Invoices' }"
            active-class="nav-active"
            @click.native="closeNav"
          >
            <img
              src="@/assets/images/icons/invoices.svg"
              alt="icons"
              class="h-6 w-6"
            />
            <span>Invoices</span>
          </router-link>
        </li>

        <li>
          <router-link
            :to="{ name: 'Agent Invoices Batch' }"
            active-class="nav-active"
            @click.native="closeNav"
          >
            <i class="fas fa-briefcase text-xl mr-1"></i>
            <span>Batch Invoice</span>
          </router-link>
        </li>

        <li>
          <router-link
            :to="{ name: 'Agent Transactions' }"
            active-class="nav-active"
            @click.native="closeNav"
          >
            <img
              src="@/assets/images/icons/revenues.svg"
              alt="icons"
              class="h-6 w-6"
            />
            <span>Transactions</span>
          </router-link>
        </li>

        <li>
          <router-link
            :to="{ name: 'Agent Receipts' }"
            active-class="nav-active"
            @click.native="closeNav"
          >
            <i class="fas fa-receipt text-xl mr-1"></i>
            <span>Receipts</span>
          </router-link>
        </li>

        <!-- route for agents only -->
        <li
          v-if="
            $store.state.general.user.userTypeId == '321ac5435be36a9552c1199a'
          "
        >
          <!-- 321ac5435be36a9552c1199a -->
          <router-link
            :to="{ name: 'Agent Sub Agents' }"
            active-class="nav-active"
            @click.native="closeNav"
          >
            <img
              src="@/assets/images/icons/agents.svg"
              alt="icons"
              class="h-6 w-6"
            />
            <span>Sub-Agents</span>
          </router-link>
        </li>
      </ul>
    </nav>
  </transition>
</template>

<script>
export default {
  name: "SideNav",

  methods: {
    closeNav() {
      this.$store.commit("general/toggleNavBar", false);
    },
  },
};
</script>

<style scoped>
nav {
  box-shadow: 0px 0px 4px 0px #a6a5a5;
}
a {
  @apply hover:bg-ansGreen hover:text-white flex gap-3 px-6 py-3 text-ansBlack mt-1;
}
.nav-active {
  @apply bg-ansGreen text-white;
}
.nav-active > img,
a:hover > img {
  filter: invert(100%) sepia(46%) saturate(147%) hue-rotate(136deg)
    brightness(114%) contrast(100%) !important;
}

/* ****************** Slide transistion */
.slidex-leave-active,
.slidex-enter-active {
  transition: 0.5s;
}
.slidex-enter {
  transform: translate(-100%, 0);
}
.slidex-leave-to {
  transform: translate(-100%, 0);
}
</style>
