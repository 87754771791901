<template>
  <span
    @click="toggle"
    class="w-16 h-7 flex items-center bg-gray-300 rounded-full p-1 duration-300 ease-in-out"
    :class="{ 'bg-green-500': value }"
  >
    <span
      class="bg-white w-8 h-5 rounded-full shadow-md transform duration-300 ease-in-out"
      :class="{ 'translate-x-6': value }"
    ></span>
  </span>
</template>

<script>
export default {
  name: "Switcher",
  data() {
    return {
      toggleActive: false,
    };
  },

  props: {
    value: {
      type: Boolean,
      default: false,
    },
  },

  methods: {
    toggle() {
      this.$emit("input", !this.value);
    },
  },
};
</script>
